/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Swal, { SweetAlertResult } from 'sweetalert2';
import EventBus from '../../common/EventBus';
import {
    IPlanningDetails,
    IPlanningEvent,
    IPlanningFree,
    IPlanningFormFields,
    IPlanningStatus,
    IPlanningEventData,
    IPlanningPeople,
    IPlanningDevice,
    IPlanningVehicle,
    IPLanningLicense,
    IPlanningActivity,
    PlanningFormType,
    IPlanningIVL,
    IPlanningIVLInstrument,
    IPlanningIVLVehicle,
    IPlanningIVLLicense
} from '../../helpers/interfaces/planning';
import { iFormField, iFormFieldValue, iFormFieldOption } from '../../helpers/interfaces/generic';
import APIPlanningService from '../../services/api/planning.service';
import Modal from '../../common/Modal';
import ModalForm from '../../common/ModalForm';
import { planningChangeStatusFormFields, planningFormFields } from '../../config/formFields/planning';
import commesseService from '../../services/api/commesse.service';
import { TableBSColumn } from '../../common/TableBootstrap';
import attivitaService from '../../services/api/database/attivita.service';
import PlanningMonth, { IPlanningMonth } from './planning-month';
import ObjectToArray from '../../helpers/ObjectToArray';
import { fixDate, getGiornoSettimana, getGiornoSettimanaAbbreviato } from '../../helpers/calendar';
import PlanningService from './../../services/planning.service';
import ClientiService from "../../services/api/clienti.service";
import { Activity, JoborderResponse, Customer } from '../../helpers/interfaces/joborders';
import { FormikProps } from 'formik';
import moment from "moment";
import 'moment/locale/it';
import './planning.css';

type Props = {
    history: {
        push(url: string): void,
    }
};

type State = {
    year: number,
    month: number,
    planningColumns: TableBSColumn<IPlanningEvent>[][],
    showModal: boolean,
    showModalForm: boolean,
    planningDetails: IPlanningDetails | null,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    jobOrderList: JoborderResponse['active'],
    jobOrders: iFormFieldOption[],
    activityList: Activity[],
    activities: iFormFieldOption[],
    filterActivities: iFormFieldOption[],
    selectedActivities: Activity[],
    statuses: iFormFieldOption[],
    people: iFormFieldOption[],
    selectedPeople: iFormFieldOption[],
    devices: iFormFieldOption[],
    selectedDevices: iFormFieldOption[],
    filterDevices: iFormFieldOption[],
    vehicles: iFormFieldOption[],
    selectedVehicles: iFormFieldOption[],
    licenses: iFormFieldOption[],
    selectedLicenses: iFormFieldOption[],
    customers: iFormFieldValue[],
    modalFormType: PlanningFormType,
    modalFormTitle: string,
    formFields: iFormField[],
    formInitialValues: IPlanningEvent | {},
};

type IApiSubmit = ((id: number, data?: any) => Promise<any>) | ((data: any) => Promise<any>);

export type IPlanningMonthType = 'people' | 'instruments' | 'vehicles' | 'licenses';

export default class PlanningPage extends Component<Props, State> {
    apiSubmit: IApiSubmit = async () => { };
    isEdit: boolean = false;
    loadHandlerSpinner: boolean = false;

    priority: iFormFieldOption[] = [
        { value: "U", label: "Urgente" },
        { value: "A", label: "Alta" },
        { value: "M", label: "Media" },
        { value: "B", label: "Bassa" }
    ];

    constructor(props: Props) {
        super(props);

        const date = new Date();

        this.state = {
            year: date.getFullYear(),
            month: date.getMonth(),
            planningColumns: [],
            showModal: false,
            showModalForm: false,
            startDate: null,
            endDate: null,
            jobOrderList: [],
            jobOrders: [],
            activityList: [],
            activities: [],
            filterActivities: [],
            selectedActivities: [],
            statuses: [],
            people: [],
            selectedPeople: [],
            filterDevices: [],
            selectedDevices: [],
            devices: [],
            selectedVehicles: [],
            vehicles: [],
            selectedLicenses: [],
            licenses: [],
            customers: [],
            planningDetails: null,
            modalFormType: 'add',
            modalFormTitle: 'Crea un nuovo evento',
            formFields: [],
            formInitialValues: {}
        }
    }

    async resourceHandler(
        date: moment.Moment,
        type: 'start' | 'end'
    ): Promise<any> {
        if (type === 'start') {
            const prevDate = this.state.startDate;
            this.setState({ startDate: date }, async () => {
                if (date !== prevDate) {
                    await this.getFreeBetweenTwoDates()
                }
            })
        } else if (type === 'end') {
            const prevDate = this.state.endDate;
            this.setState({ endDate: date }, async () => {
                if (date !== prevDate) {
                    await this.getFreeBetweenTwoDates()
                }
            })
        }
    }

    async getFilterActivities(value: any): Promise<iFormFieldOption[]> {
        const jobOrder = this.state.jobOrderList.filter((item) => {
            return item.id === value
        })[0];

        if (!jobOrder) {
            Swal.fire(
                "Errore",
                "Non è stato possibile recuperare la commessa selezionata",
                "error"
            );

            return [];
        }

        let activities: Activity[] = [];

        if (jobOrder.rittype.id === 1) {
            activities = this.state.activityList.filter(
                (item) => {
                    return item.type === 'O'
                }
            )
        } else if (jobOrder.rittype.id === 2) {
            activities = this.state.activityList.filter(
                (item) => {
                    return item.type === 'C'
                }
            )
        } else if (jobOrder.rittype.id === 3) {
            activities = this.state.activityList.filter(
                (item) => {
                    return item.type === 'G'
                }
            )
        } else {
            activities = this.state.activityList;
        }

        return activities.map((activity) => {
            return {
                value: activity.id,
                label: `${activity.code} - ${activity.descrizione}`
            };
        });
    }

    async selectJobOrder(
        value: number,
        formik?: FormikProps<IPlanningFormFields>,
    ): Promise<void> {
        await this.getFilterActivities(value).then((
            filterActivities
        ) => {
            this.setState({
                filterActivities
            }, async () => {
                if (
                    formik?.values['activities'] &&
                    formik?.values['activities'].length
                ) {
                    await this.selectActivities(
                        formik.values['activities'],
                        formik
                    )
                } else {
                    this.setState({
                        formFields: planningFormFields(
                            this.state.people,
                            this.state.filterDevices,
                            this.state.jobOrders,
                            this.state.filterActivities,
                            this.state.vehicles,
                            this.state.licenses,
                            this.state.statuses,
                            this.priority,
                            this.state.customers,
                            this.resourceHandler.bind(this),
                            this.selectJobOrder.bind(this),
                            this.selectActivities.bind(this)
                        )
                    })
                }
            });
        }).catch((e) => {
            Promise.reject(
                'Siamo spiacenti, si è verificato un errore imprevisto'
            );
        });
    }

    async filterActivitiesInstruments() {
        let filterDevices: iFormFieldOption[] = [];

        if (this.state.devices.length) {
            if (!this.state.selectedActivities.length) {
                filterDevices = this.state.devices
            } else {
                this.state.selectedActivities.forEach((
                    activity: Activity
                ) => {
                    activity.instruments.forEach((
                        instrument: number
                    ) => {
                        this.state.devices.forEach((
                            device
                        ) => {
                            let instrumentExists = false;

                            if (
                                instrument === device.value &&
                                !instrumentExists &&
                                !filterDevices.filter((value) => {
                                    return value === device
                                }).length
                            ) {
                                filterDevices.push(device);
                            }
                        })
                    });
                });
            }

            this.setState(({ filterDevices }), () => {
                this.setState({
                    formFields: planningFormFields(
                        this.state.people,
                        this.state.filterDevices,
                        this.state.jobOrders,
                        this.state.filterActivities,
                        this.state.vehicles,
                        this.state.licenses,
                        this.state.statuses,
                        this.priority,
                        this.state.customers,
                        this.resourceHandler.bind(this),
                        this.selectJobOrder.bind(this),
                        this.selectActivities.bind(this)
                    )
                });
            })
        }
    }

    async selectActivities(
        values: (iFormFieldOption | number)[],
        formik?: FormikProps<IPlanningFormFields>,
    ): Promise<void> {
        let activityids: any[];
        if (typeof (values[0]) === 'number') {
            activityids = values;
        } else {
            activityids = values.map((activity: any) => {
                return Number(activity.value)
            });
        }

        let selectedActivities: Activity[] = [];

        this.state.activityList.forEach((
            activity: Activity
        ) => {
            activityids.forEach((id: number) => {
                if (id === activity.id) {
                    selectedActivities.push(activity);
                }
            });
        });

        this.setState({ selectedActivities }, async () => {
            if (this.state.devices.length) {
                await this.filterActivitiesInstruments();
            }

            this.setState({
                formFields: planningFormFields(
                    this.state.people,
                    this.state.filterDevices,
                    this.state.jobOrders,
                    this.state.filterActivities,
                    this.state.vehicles,
                    this.state.licenses,
                    this.state.statuses,
                    this.priority,
                    this.state.customers,
                    this.resourceHandler.bind(this),
                    this.selectJobOrder.bind(this),
                    this.selectActivities.bind(this)
                ),
            });
        });
    }

    /** Load people, devices, vehicles and licesnses */
    async getFreeBetweenTwoDates() {
        const { startDate, endDate } = this.state;

        if (startDate && endDate) {
            await APIPlanningService.getFreeBetweenTwoDates(
                startDate.format('yyyy-MM-DDTHH:mm:ssZ'),
                endDate.format('yyyy-MM-DDTHH:mm:ssZ')
            ).then((data: IPlanningFree) => {
                const {
                    selectedPeople, selectedDevices, selectedVehicles, selectedLicenses
                } = this.state;

                let people: iFormFieldOption[] = [];
                let personIds: number[] = [];

                ObjectToArray<IPlanningFree['resources'][]>(
                    data.resources
                ).forEach((person: any) => {
                    people.push({
                        value: person.id,
                        label: person.fullname
                    });

                    personIds.push(person.id);
                });

                selectedPeople.forEach((
                    option: iFormFieldOption
                ) => {
                    if (
                        !personIds.includes((Number(option.value)))
                    ) {
                        people.push(option);
                    }
                });

                let devices: iFormFieldOption[] = [];
                let deviceIds: number[] = [];

                ObjectToArray<IPlanningFree['devices'][]>(
                    data.devices
                ).forEach((device: any) => {
                    devices.push({
                        value: device.id,
                        label: device.fullname
                    });

                    deviceIds.push(device.id);
                });

                selectedDevices.forEach((
                    option: iFormFieldOption
                ) => {
                    if (
                        !deviceIds.includes((Number(option.value)))
                    ) {
                        devices.push(option);
                    }
                });

                let vehicles: iFormFieldOption[] = [];
                let vehicleIds: number[] = [];

                ObjectToArray<IPlanningFree['vehicles'][]>(
                    data.vehicles
                ).forEach((vehicle: any) => {
                    vehicles.push({
                        value: vehicle.id,
                        label: vehicle.fullname
                    });

                    vehicleIds.push(vehicle.id);
                });

                selectedVehicles.forEach((
                    option: iFormFieldOption
                ) => {
                    if (
                        !vehicleIds.includes((Number(option.value)))
                    ) {
                        vehicles.push(option);
                    }
                });

                let licenses: iFormFieldOption[] = [];
                let licenseIds: number[] = [];

                ObjectToArray<IPlanningFree['licenses'][]>(
                    data.licenses
                ).forEach((license: any) => {
                    licenses.push({
                        value: license.id,
                        label: license.fullname
                    });

                    licenseIds.push(license.id);
                });

                selectedLicenses.forEach((
                    option: iFormFieldOption
                ) => {
                    if (
                        !licenseIds.includes((Number(option.value)))
                    ) {
                        licenses.push(option);
                    }
                });

                this.loadHandlerSpinner = true;

                this.setState({
                    people,
                    devices,
                    vehicles,
                    licenses,
                }, async () => {
                    await this.filterActivitiesInstruments();

                    await this.loadHandler(
                        people,
                        this.state.filterDevices,
                        vehicles,
                        licenses,
                        false
                    );
                });
            }).catch((e) => { return Promise.reject() });
        } else {
            this.setState({
                people: [],
                devices: [],
                vehicles: [],
                licenses: [],
                formFields: planningFormFields(
                    [],
                    this.state.filterDevices,
                    this.state.jobOrders,
                    this.state.filterActivities,
                    [],
                    [],
                    this.state.statuses,
                    this.priority,
                    [],
                    this.resourceHandler.bind(this),
                    this.selectJobOrder.bind(this),
                    this.selectActivities.bind(this)
                ),
            });
        }
    }

    async addHandler(
        initialValues: {
            persons?: number[],
            devices?: number[],
            vehicles?: number[],
            licenses?: number[],
            start?: moment.Moment,
            end?: moment.Moment,
        } = {}
    ) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        const date = new Date();

        if (!initialValues.start) {
            initialValues.start = moment(
                date.setHours(8, 0, 0)
            )
        }

        if (!initialValues.end) {
            initialValues.end = moment(
                date.setHours(17, 0, 0)
            )
        }

        this.setState({
            startDate: initialValues.start
                ? moment(initialValues.start)
                : null,
            endDate: initialValues.end
                ? moment(initialValues.end)
                : null,
        }, async () => {
            await this.openModalForm(
                {
                    ...initialValues,
                    start: initialValues.start?.format('yyyy-MM-DDTHH:mm:ssZ'),
                    end: initialValues.end?.format('yyyy-MM-DDTHH:mm:ssZ'),
                    priority: 'M'
                },
                APIPlanningService.addEvent,
                'add',
                "Crea un nuovo evento"
            )

            // se il form ha le date precompilate serve un timeout sull' hideLoader
            setTimeout(() => {
                EventBus.dispatch("hideLoader");
            }, 1000);
        });
    }

    async viewHandler(idEvento: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        await APIPlanningService.getEvent(idEvento).then(
            async (planningDetails: IPlanningDetails | null) => {
                if (planningDetails) {
                    this.setState({
                        showModal: true,
                        planningDetails,
                        // Init form fields
                        formFields: planningFormFields(
                            [],
                            [],
                            [],
                            [],
                            [],
                            [],
                            [],
                            this.priority,
                            [],
                            this.resourceHandler.bind(this),
                            this.selectJobOrder.bind(this),
                            this.selectActivities.bind(this)
                        )
                    });
                } else {
                    Swal.fire('Siamo spiacenti, l\'evento selezionato per la visualizzazione non è stato trovato.')
                }
            },
        );

        EventBus.dispatch("hideLoader");
    };

    async editHandler(idEvento: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        await APIPlanningService.getEvent(idEvento).then(
            async (planningDetails: IPlanningDetails) => {
                if (planningDetails) {
                    this.setState({
                        startDate: moment(planningDetails.start),
                        endDate: moment(planningDetails.end)
                    }, async () => {
                        this.isEdit = true;
                        this.apiSubmit = APIPlanningService.editEvent;

                        let priority: any = '';

                        this.priority.forEach((element: iFormFieldOption) => {
                            if (element.label === planningDetails.priority) {
                                priority = element.value.toString()
                            }
                        });

                        const activities = planningDetails.activities.length ? planningDetails.activities.map((
                            activity
                        ) => {
                            return activity.id
                        }) : [];

                        const formInitialValues: IPlanningFormFields = {
                            id: planningDetails.id,
                            persons: planningDetails.persons.map((person) => {
                                return person.id
                            }),
                            devices: planningDetails.devices.length ? planningDetails.devices.map((
                                device: IPlanningDevice
                            ) => {
                                return device.id
                            }) : [],
                            joborder: planningDetails.joborder.id,
                            activities,
                            start: planningDetails.start,
                            end: planningDetails.end,
                            note: planningDetails.note,
                            stato: planningDetails.stato.id,
                            vehicles: planningDetails.vehicles.length ? planningDetails.vehicles.map((
                                vehicle: IPlanningVehicle
                            ) => {
                                return vehicle.id
                            }) : [],
                            licenses: planningDetails.licenses.length ? planningDetails.licenses.map((
                                license: IPLanningLicense
                            ) => {
                                return license.id
                            }) : [],
                            customer: planningDetails.customer.id,
                            priority,
                            address: planningDetails.address
                        }

                        const people: iFormFieldOption[] = planningDetails.persons.map((
                            person: IPlanningPeople
                        ) => {
                            return {
                                label: person.fullname,
                                value: person.id
                            }
                        });

                        const devices: iFormFieldOption[] = planningDetails.devices.map((
                            device: IPlanningDevice
                        ) => {
                            return {
                                label: device.marca_modello,
                                value: device.id
                            }
                        });

                        const vehicles: iFormFieldOption[] = planningDetails.vehicles.map((
                            vehicle: IPlanningVehicle
                        ) => {
                            return {
                                label: `${vehicle.brand} ${vehicle.model} Targa: ${vehicle.plate}`,
                                value: vehicle.id
                            }
                        });

                        const licenses: iFormFieldOption[] = planningDetails.licenses.map((
                            license: IPLanningLicense
                        ) => {
                            return {
                                label: `${license.prodotto.nome} ${license.prodotto.produttore}`,
                                value: license.id
                            }
                        });

                        this.setState({
                            selectedPeople: people,
                            selectedDevices: devices,
                            selectedVehicles: vehicles,
                            selectedLicenses: licenses
                        }, async () => {
                            await this.getFreeBetweenTwoDates().then(() => {
                                this.loadHandlerSpinner = false;

                                this.setState({
                                    filterDevices: this.state.devices,
                                    planningDetails,
                                    formInitialValues,
                                }, async () => {
                                    await this.loadHandler(
                                        this.state.people,
                                        this.state.filterDevices,
                                        this.state.vehicles,
                                        this.state.licenses,
                                        false
                                    );

                                    let selectedActivities: Activity[] = [];
                                    let activityValues: iFormFieldOption[] = [];

                                    this.state.activityList.forEach((activity) => {
                                        activities.forEach((activityId) => {
                                            if (activity.id === activityId) {
                                                selectedActivities.push(activity);
                                                activityValues.push(({
                                                    value: activity.id,
                                                    label: `${activity.code} - ${activity.descrizione}`
                                                }))
                                            }
                                        });
                                    });

                                    await this.selectJobOrder(
                                        planningDetails.joborder.id
                                    );

                                    this.setState({
                                        selectedActivities,
                                    }, async () => {
                                        await this.selectActivities(
                                            activityValues,
                                            undefined,
                                        );
                                    });

                                    this.setState({
                                        modalFormType: 'edit',
                                        modalFormTitle: `Modifica un'evento`
                                    }, () => {
                                        this.setState({ showModalForm: true });
                                    });

                                    this.isEdit = false;
                                    EventBus.dispatch("hideLoader");
                                });
                            }).catch((e) => {
                                Promise.reject();
                            });
                        });
                    })
                } else {
                    EventBus.dispatch("hideLoader");
                    Swal.fire('Siamo spiacenti, l\'evento selezionato per la modifica non è stato trovato.')
                }
            }
        ).catch(() => {
            EventBus.dispatch("hideLoader");
            Swal.fire('Siamo spiacenti, si è verificato un errore imprevisto durante il caricamento dei dati.');
        })
    };

    async changeStatusHandler(idEvento: number) {
        await APIPlanningService.getEvent(idEvento).then(
            async (planningDetails: IPlanningDetails) => {
                if (planningDetails) {
                    APIPlanningService.status().then((data) => {
                        const statuses: iFormFieldOption[] = data.length ? data.map((status: IPlanningStatus) => {
                            return { value: status.id, label: status.nome };
                        }) : [];

                        this.apiSubmit = APIPlanningService.changeStatusEvent;

                        this.setState({
                            formFields: planningChangeStatusFormFields(statuses),
                            formInitialValues: {
                                id: planningDetails.id,
                                stato: planningDetails.stato.id
                            },
                            modalFormType: 'change',
                            modalFormTitle: `Modifica lo stato dell'evento`
                        }, async () => {
                            this.setState({
                                showModalForm: true
                            })
                        });
                    }).catch((e) => {
                        Swal.fire('Siamo spiacenti, Non è stato possibile caricare la lista degli stati degli eventi.')
                    })
                } else {
                    Swal.fire('Siamo spiacenti, l\'evento selezionato per la modifica dello stato non è stato trovato.')
                }
            },
        );
    }

    async deleteHandler(idEvento: number) {
        Swal.fire({
            title: 'Sei sicuro di voler cancellare questo evento?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#33dd33',
            cancelButtonColor: '#dd3333',
            confirmButtonText: 'Continua',
            cancelButtonText: 'Annulla'
        }).then(async (result: SweetAlertResult<any>) => {
            if (result.isConfirmed) {
                await APIPlanningService.deleteEvent(idEvento)
                    .then(() => {
                        Swal.fire(
                            'Cancellazione dell\'evento effettuata con successo.',
                            '',
                            'success'
                        ).then(() => {
                            window.location.reload();
                        })
                    }).catch((e) => Swal.fire(
                        'Siamo spiacenti, l\'evento selezionato per la cancellazione non è stato trovato.',
                        '',
                        'error'
                    ));
            };
        });
    };

    async openModalForm(
        formInitialValues: IPlanningEvent | {},
        apiSubmit: IApiSubmit,
        modalFormType: PlanningFormType,
        modalFormTitle: string
    ) {
        this.apiSubmit = apiSubmit;

        this.loadHandlerSpinner = false;
        await this.loadHandler().then(() => {
            this.setState({
                formInitialValues,
                modalFormType,
                modalFormTitle
            }, async () => {
                await this.getFreeBetweenTwoDates().then(() => {
                    this.setState({
                        showModalForm: true,
                    });
                }).catch((e) => {
                    Promise.reject(e);
                });
            });
        }).catch((e) => {
            Promise.reject();
        });
    }

    /** Load jobOrders, activities, status and customers */
    async loadHandler(
        people: iFormFieldOption[] = [],
        devices: iFormFieldOption[] = [],
        vehicles: iFormFieldOption[] = [],
        licenses: iFormFieldOption[] = [],
        setFilterActivities: boolean = true,
    ) {
        if (this.loadHandlerSpinner) EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        await Promise.all([
            commesseService.all(),
            attivitaService.getAllDatore(),
            APIPlanningService.status(),
            ClientiService.getAll()
        ]).then((data: [
            JoborderResponse,
            Activity[],
            IPlanningStatus[],
            Customer[]
        ]) => {
            const jobOrders: iFormFieldOption[] = data[0].active.length
                ? data[0].active.map((jobOrder: any) => {
                    return {
                        value: jobOrder.id,
                        label: jobOrder.name
                    };
                }) : [];

            const activities: iFormFieldOption[] = data[1].length
                ? data[1].map((activity: any) => {
                    return {
                        value: activity.id,
                        label: `${activity.code} - ${activity.descrizione}`
                    };
                }) : [];

            const statuses: iFormFieldOption[] = data[2].length
                ? data[2].map((status: IPlanningStatus) => {
                    return {
                        value: status.id,
                        label: status.nome
                    };
                }) : [];

            const customers: iFormFieldValue[] = data[3].length
                ? data[3].map((item: Customer) => {
                    return {
                        key: item.id,
                        value: item.ragionesociale
                    }
                }) : [];

            const filterDevices: iFormFieldOption[] = devices.length
                ? Array.from(devices)
                : this.state.devices;

            this.setState({
                jobOrderList: data[0].active,
                jobOrders,
                activityList: data[1],
                activities,
                filterActivities: setFilterActivities
                    ? Array.from(activities)
                    : this.state.filterActivities,
                filterDevices,
                statuses,
                customers,
            }, () => {
                this.setState({
                    formFields: planningFormFields(
                        people,
                        this.state.filterDevices,
                        jobOrders,
                        this.state.filterActivities,
                        vehicles,
                        licenses,
                        statuses,
                        this.priority,
                        customers,
                        this.resourceHandler.bind(this),
                        this.selectJobOrder.bind(this),
                        this.selectActivities.bind(this)
                    ),
                });
            });
        }).catch((error: any) => {
            Swal.fire('Siamo spiacenti, si è verificato un errore imprevisto durante il caricamento dei dati', '', 'error');
            Promise.reject();
        });

        if (this.loadHandlerSpinner) EventBus.dispatch("hideLoader");
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    closeModalForm() {
        this.setState({
            showModalForm: false,
            startDate: null,
            endDate: null,
            selectedActivities: [],
            filterActivities: [],
            filterDevices: [],
        });
    }

    handleSuccess() {
        window.location.hash = "#planning";
        window.location.reload();
    }

    setIVLEvents(data: IPlanningIVL, type: IPlanningMonthType) {
        let plannings: IPlanningEventData[] = [];
        if (type === 'instruments') {
            data.instruments.length && data.instruments.forEach((result: IPlanningIVLInstrument) => {
                if (!plannings[result.instrument.id]) {
                    plannings[result.instrument.id] = {
                        resource: {
                            ...result.instrument,
                            fullname: `${result.instrument.marca_modello} (${result.instrument.serial_number})`,
                            type,
                        },
                        plannings: []
                    }
                }

                result.plannings.length && result.plannings.forEach((planningEvent: IPlanningDetails) => {
                    plannings[result.instrument.id].plannings.push({ ...planningEvent, type: 'I' });
                });
            });
        }

        if (type === 'vehicles') {
            data.vehicles.length && data.vehicles.forEach((result: IPlanningIVLVehicle) => {
                if (!plannings[result.vehicle.id]) {
                    plannings[result.vehicle.id] = {
                        resource: {
                            ...result.vehicle,
                            fullname: `${result.vehicle.brand} ${result.vehicle.model} (${result.vehicle.plate})`,
                            type,
                        },
                        plannings: []
                    }
                }

                result.plannings.length && result.plannings.forEach((planningEvent: IPlanningDetails) => {
                    plannings[result.vehicle.id].plannings.push({ ...planningEvent, type: 'V' });
                });
            });
        }

        if (type === 'licenses') {
            data.licenses.length && data.licenses.forEach((result: IPlanningIVLLicense) => {
                if (!plannings[result.license.id]) {
                    plannings[result.license.id] = {
                        resource: {
                            ...result.license,
                            fullname: `${result.license.prodotto.produttore} ${result.license.prodotto.nome} (${result.license.identificativo})`,
                            type,
                        },
                        plannings: []
                    }
                }

                result.plannings.length && result.plannings.forEach((planningEvent: IPlanningDetails) => {
                    plannings[result.license.id].plannings.push({ ...planningEvent, type: 'L' });
                });
            });
        }

        return plannings;
    }

    async onAddClick(day: number, month: number, year: number, id: number, type: IPlanningMonthType, fullname: string) {

        if (type === 'people') {
            this.setState({
                selectedPeople: [{
                    value: id,
                    label: fullname
                }]
            })
        } else if (type === 'instruments') {
            this.setState({
                selectedDevices: [{
                    value: id,
                    label: fullname
                }]
            })
        } else if (type === 'licenses') {
            this.setState({
                selectedLicenses: [{
                    value: id,
                    label: fullname
                }]
            })
        } else if (type === 'vehicles') {
            this.setState({
                selectedVehicles: [{
                    value: id,
                    label: fullname
                }]
            })
        }

        await this.addHandler({
            persons: type === 'people' ? [Number(id)] : undefined,
            devices: type === 'instruments' ? [Number(id)] : undefined,
            vehicles: type === 'vehicles' ? [Number(id)] : undefined,
            licenses: type === 'licenses' ? [Number(id)] : undefined,
            start: moment(fixDate(month) + '/' + fixDate(day) + '/' + year + ' 08:00:00'),
            end: moment(fixDate(month) + '/' + fixDate(day) + '/' + year + ' 17:00:00'),
        });
    }

    async getMonth(
        year: number,
        month: number,
        types: IPlanningMonthType[]
    ): Promise<IPlanningMonth> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        const startDate = moment(`${year}-${fixDate(month + 1)}-01 00:00:00`);
        const daysInMonth = startDate.daysInMonth();
        const endDate = moment(`${year}-${fixDate(month + 1)}-${fixDate(daysInMonth)} 23:59:59`);

        let planningDates: IPlanningEventData[] | null = null;

        if (types.find(type => type === 'people'))
            planningDates = await PlanningService.getPeopleBetweenTwoDates(
                APIPlanningService.getPeopleBetweenTwoDates,
                startDate,
                endDate
            );

        const otherTypes = types.filter(type => type !== 'people')
        let newTypes: IPlanningEventData[] = []

        if (otherTypes.length > 0) {
            const currentPlanningDates = await PlanningService.getIVLBetweenTwoDates(
                startDate,
                endDate,
            );
            if (currentPlanningDates) {
                otherTypes.forEach(type => {
                    const events = this.setIVLEvents(currentPlanningDates, type)
                    newTypes = newTypes.concat(events)
                })
            }
        }
        if (planningDates)
            planningDates = planningDates.concat(newTypes)
        else
            planningDates = newTypes

        let weekColumns: TableBSColumn<IPlanningEventData>[] = [
            {
                id: "resource_header",
                name: "",
            }
        ];

        let resourceNames: string[] = [];
        let resourceName: string = "";
        types.forEach(type => {
            if (type === 'people') {
                resourceNames.push('Dipendenti');
            } else if (type === 'instruments') {
                resourceNames.push('Strumenti');
            } else if (type === 'vehicles') {
                resourceNames.push('Veicoli');
            } else if (type === 'licenses') {
                resourceNames.push('Licenze');
            }
        })
        resourceName = resourceNames.join(" - ")

        let dayColumns: TableBSColumn<IPlanningEventData>[] = [
            {
                id: "resource",
                name: resourceName,
                data: "resource",
                render: (data: IPlanningEventData['resource']) => {
                    return <span>{data.fullname}</span>
                },
            }
        ];

        let dayCount = 0;
        // const today = new Date();

        for (let i = 1; i <= daysInMonth; i++) {
            const today = moment(new Date()).format("YYYY-MM-DD");
            const currentDate = moment(`${year}-${fixDate(month + 1)}-${fixDate(i)}`).format("YYYY-MM-DD");
            dayColumns.push({
                id: `d_${getGiornoSettimana(year, month + 1, i).toLowerCase()}_${i}${today === currentDate ? "_today" : ""}`,
                name: `${getGiornoSettimanaAbbreviato(
                    year,
                    month + 1,
                    i
                )} ${i}`,
                data: "plannings",
                className: "href='#'",
                render: (
                    data: IPlanningEventData['plannings'],
                    _index: number,
                    row: IPlanningEventData
                ) => {
                    return data.length ? data.map((
                        value: any,
                        index: number
                    ) => {
                        let startValue: string | undefined;
                        let endValue: string | undefined;
                        let description: string | undefined;
                        let color: string | undefined;

                        if (
                            value.type === 'P' ||
                            value.type === 'I' ||
                            value.type === 'V' ||
                            value.type === 'L'
                        ) {
                            startValue = value.start;
                            endValue = value.end;
                            description = `${value.joborder.name} (${value.customer.ragione_sociale})`;
                            color = value.stato.color;
                        } else if (value.type === 'U') {
                            startValue = value.inizio;
                            endValue = value.fine;
                            description = `${value.causale} (${value.stato})`
                            color = value.colore
                        }

                        if (!startValue || !endValue || !description || !color) {
                            return null;
                        }

                        const date = moment(`${year}-${fixDate(month + 1)}-${fixDate(i)}`)
                        const start = moment(startValue);
                        const end = moment(endValue);

                        const show = date.isBetween(start, end, undefined, '[]') ||
                            (start.date() === i && start.month() === month) ||
                            (end.date() === i && end.month() === month);

                        const pers: any = value?.persons ? (Object.keys(value.persons)
                            ? Object.keys(value.persons)
                            : (value.persons.length ? value.persons : null)
                        ) : null;

                        const noPerson: boolean = (pers && pers.length === 0) ? true : false;

                        return <React.Fragment key={index}>
                            {
                                index === 0 ? <a className='btn-add' onClick={() => this.onAddClick(Number(fixDate(i)), Number(fixDate(month + 1)), year, row.resource.id, row.resource.type, row.resource.fullname)} title='Aggiungi evento'>
                                    <i className='fa fa-plus-circle mb-2' />
                                </a> : <></>
                            }
                            {
                                show ? <div key={index} className='event d-block p-1 pl-2 pr-2 mb-2 rounded small' style={{ background: color }}>
                                    <div>
                                        {(value.type === 'P' || noPerson)
                                            ? <a className='view_btn' data-id={`${value.id}`} title='Visualizza evento'>
                                                <i className='fa fa-eye' /> Visualizza
                                            </a>
                                            : <></>
                                        }
                                    </div>
                                    {start.format('HH:mm')}-{end.format('HH:mm')}: {description}
                                </div> : <React.Fragment key={index}></React.Fragment>
                            }
                        </React.Fragment>
                    }) : <div>
                        <a className='btn-add' onClick={() => this.onAddClick(Number(fixDate(i)), Number(fixDate(month + 1)), year, row.resource.id, row.resource.type, row.resource.fullname)} title='Aggiungi evento'>
                            <i className='fa fa-plus-circle mb-2' />
                        </a>
                    </div>
                }
            })

            dayCount++;

            const dayDate: moment.Moment = moment(`${year}-${fixDate(month + 1)}-${fixDate(i)}`);
            const dayOfWeek = dayDate.weekday();

            if (dayOfWeek === 6 || i === daysInMonth) {
                const weekOfYear = dayDate.week().toString();

                weekColumns.push(
                    {
                        id: `w_${weekOfYear}`,
                        name: weekOfYear,
                        headerColspan: dayCount
                    }
                )

                dayCount = 0;
            }
        }

        EventBus.dispatch("hideLoader");

        return {
            planningColumns: [
                [...weekColumns],
                [...dayColumns]
            ],
            planningData: planningDates
        }
    }

    async getInterval(
        selectedStartDate: string,
        selectedEndDate: string,
        types: IPlanningMonthType[]
    ): Promise<IPlanningMonth> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        const startDate = moment(selectedStartDate);
        const daysInMonth = startDate.daysInMonth(); // n.ro di giorni nel mese
        const endDate = moment(selectedEndDate);

        let dayInterval: any[] = [];
        let monthInterval: any[] = [];
        let yearInterval: any[] = [];

        const _startdate = selectedStartDate.split("-");

        const startYear = Number(_startdate[0]);
        const startMonth = Number(_startdate[1]);
        const startDay = Number(_startdate[2]);

        const _enddate = selectedEndDate.split("-");

        const endYear = Number(_enddate[0]);
        const endMonth = Number(_enddate[1]);
        const endDay = Number(_enddate[2]);

        // intervallo di tempo massimo: 31 giorni

        /*
        // la logica gestisce solo un intervallo di un mese
        // cioe' date con lo stesso mese o date con mesi successivi
        if (startYear === endYear) {
            if (startMonth === endMonth) {
                for (let i = startDay; i <= endDay; i++) {
                    dayInterval.push(i);
                    monthInterval.push(startMonth);
                    yearInterval.push(startYear);
                }
            } else {
                for (let i = startDay; i <= daysInMonth; i++) {
                    dayInterval.push(i);
                    monthInterval.push(startMonth);
                    yearInterval.push(startYear);
                }

                // per gestire il msese di febbraio che avendo solo 28/29 giorni
                // consente un intervallo che copre tre mesi
                if (endMonth - startMonth > 1) {
                    const middleYear = Number(_startdate[0]);
                    const middleMonth = Number(_startdate[1]) + 1;

                    let _month = middleMonth.toString();
                    if (_month.length === 1) { _month = '0' + _month; };

                    // const middleDate = _startdate[0] + '-' + _month + '-' + '01';
                    const middleDate = `${middleYear}-${_month}-01`;

                    const middleDaysInMonth = moment(middleDate).daysInMonth();

                    for (let i = 1; i <= middleDaysInMonth; i++) {
                        dayInterval.push(i);
                        monthInterval.push(middleMonth);
                        yearInterval.push(middleYear);
                    }
                }

                for (let i = 1; i <= endDay; i++) {
                    dayInterval.push(i);
                    monthInterval.push(endMonth);
                    yearInterval.push(startYear);
                }
            }
        } else {
            for (let i = startDay; i <= daysInMonth; i++) {
                dayInterval.push(i);
                monthInterval.push(startMonth);
                yearInterval.push(startYear);
            }

            for (let i = 1; i <= endDay; i++) {
                dayInterval.push(i);
                monthInterval.push(endMonth);
                yearInterval.push(endYear);
            }
        } */

        // intervallo di tempo massimo: 90 giorni

        // la logica gestisce un intervallo di più mesi, ma
        // l'intervallo puo' coprire solo due anni adiacenti!
        if (startYear === endYear) {
            if (startMonth === endMonth) {
                for (let i = startDay; i <= endDay; i++) {
                    dayInterval.push(i);
                    monthInterval.push(startMonth);
                    yearInterval.push(startYear);
                }
            } else {
                for (let i = startDay; i <= daysInMonth; i++) {
                    dayInterval.push(i);
                    monthInterval.push(startMonth);
                    yearInterval.push(startYear);
                }

                const months: number = endMonth - startMonth - 1;
                for (let m = 0; m < months; m++) {
                    const month = startMonth + m + 1;

                    let _month = month.toString();
                    if (_month.length === 1) { _month = '0' + _month; };

                    const monthDate = `${startYear}-${_month}-01`;

                    const monthDaysInMonth = moment(monthDate).daysInMonth();

                    for (let i = 1; i <= monthDaysInMonth; i++) {
                        dayInterval.push(i);
                        monthInterval.push(month);
                        yearInterval.push(startYear);
                    }
                }

                for (let i = 1; i <= endDay; i++) {
                    dayInterval.push(i);
                    monthInterval.push(endMonth);
                    yearInterval.push(startYear);
                }
            }
        } else {
            for (let i = startDay; i <= daysInMonth; i++) {
                dayInterval.push(i);
                monthInterval.push(startMonth);
                yearInterval.push(startYear);
            }

            const monthsOldYear = 12 - startMonth;
            if (monthsOldYear > 0) {
                for (let m = 0; m < monthsOldYear; m++) {
                    const month = startMonth + m + 1;

                    let _month = month.toString();
                    if (_month.length === 1) { _month = '0' + _month; };

                    const monthDate = `${startYear}-${_month}-01`;

                    const monthDaysInMonth = moment(monthDate).daysInMonth();

                    for (let i = 1; i <= monthDaysInMonth; i++) {
                        dayInterval.push(i);
                        monthInterval.push(month);
                        yearInterval.push(startYear);
                    }
                }
            }

            const monthsNewYear = endMonth;
            if (monthsNewYear > 1) {
                for (let m = 0; m < monthsNewYear; m++) {
                    const month = m;

                    let _month = month.toString();
                    if (_month.length === 1) { _month = '0' + _month; };

                    const monthDate = `${endYear}-${_month}-01`;

                    const monthDaysInMonth = moment(monthDate).daysInMonth();

                    for (let i = 1; i <= monthDaysInMonth; i++) {
                        dayInterval.push(i);
                        monthInterval.push(month);
                        yearInterval.push(endYear);
                    }
                }
            }

            for (let i = 1; i <= endDay; i++) {
                dayInterval.push(i);
                monthInterval.push(endMonth);
                yearInterval.push(endYear);
            }
        }

        let planningDates: IPlanningEventData[] | null = null;

        if (types.find(type => type === 'people'))
            planningDates = await PlanningService.getPeopleBetweenTwoDates(
                APIPlanningService.getPeopleBetweenTwoDates,
                startDate,
                endDate
            );

        const otherTypes = types.filter(type => type !== 'people')
        let newTypes: IPlanningEventData[] = []

        if (otherTypes.length > 0) {
            const currentPlanningDates = await PlanningService.getIVLBetweenTwoDates(
                startDate,
                endDate,
            );
            if (currentPlanningDates) {
                otherTypes.forEach(type => {
                    const events = this.setIVLEvents(currentPlanningDates, type)
                    newTypes = newTypes.concat(events)
                })
            }
        }
        if (planningDates)
            planningDates = planningDates.concat(newTypes)
        else
            planningDates = newTypes

        let weekColumns: TableBSColumn<IPlanningEventData>[] = [
            {
                id: "resource_header",
                name: "",
            }
        ];

        let resourceNames: string[] = [];
        let resourceName: string = "";
        types.forEach(type => {
            if (type === 'people') {
                resourceNames.push('Dipendenti');
            } else if (type === 'instruments') {
                resourceNames.push('Strumenti');
            } else if (type === 'vehicles') {
                resourceNames.push('Veicoli');
            } else if (type === 'licenses') {
                resourceNames.push('Licenze');
            }
        })
        resourceName = resourceNames.join(" - ")

        let dayColumns: TableBSColumn<IPlanningEventData>[] = [
            {
                id: "resource",
                name: resourceName,
                data: "resource",
                render: (data: IPlanningEventData['resource']) => {
                    return <span>{data.fullname}</span>
                }
            }
        ];

        let dayCount = 0;

        dayInterval.map((day: number, i: number) => {
            const today = moment(new Date()).format("YYYY-MM-DD");
            const currentDate = moment(`${yearInterval[i]}-${monthInterval[i]}-${day}`).format("YYYY-MM-DD");
            dayColumns.push({
                id: `d_${getGiornoSettimana(yearInterval[i], monthInterval[i], day).toLowerCase()}_${day}${today === currentDate ? "_today" : ""}`,
                name: `${getGiornoSettimanaAbbreviato(
                    yearInterval[i],
                    monthInterval[i],
                    day
                )} ${day}`,
                data: "plannings",
                render: (
                    data: IPlanningEventData['plannings'],
                    _index: number,
                    row: IPlanningEventData
                ) => {
                    return data.length ? data.map((
                        value: any,
                        index: number
                    ) => {
                        let startValue: string | undefined;
                        let endValue: string | undefined;
                        let description: string | undefined;
                        let color: string | undefined;

                        if (
                            value.type === 'P' ||
                            value.type === 'I' ||
                            value.type === 'V' ||
                            value.type === 'L'
                        ) {
                            startValue = value.start;
                            endValue = value.end;
                            description = `${value.joborder.name} (${value.customer.ragione_sociale})`;
                            color = value.stato.color;
                        } else if (value.type === 'U') {
                            startValue = value.inizio;
                            endValue = value.fine;
                            description = `${value.causale} (${value.stato})`
                            color = value.colore
                        }

                        if (!startValue || !endValue || !description || !color) {
                            return null;
                        }

                        const date = moment(`${yearInterval[i]}-${fixDate(monthInterval[i])}-${fixDate(day)}`)
                        const start = moment(startValue);
                        const end = moment(endValue);

                        const show = date.isBetween(start, end, undefined, '[]') ||
                            (start.date() === day && start.month() === (monthInterval[i] - 1) && start.year() === yearInterval[i]) ||
                            (end.date() === day && end.month() === (monthInterval[i] - 1) && end.year() === yearInterval[i]);

                        const pers: any = value?.persons ? (Object.keys(value.persons) ? Object.keys(value.persons) :
                            (value.persons.length ? value.persons : null)
                        ) : null;

                        const noPerson: boolean = (pers && pers.length === 0) ? true : false;

                        return <React.Fragment key={index}>
                            {index === 0 ? <a className='btn-add' onClick={() => this.onAddClick(Number(fixDate(day)), Number(fixDate(monthInterval[i])), yearInterval[i], row.resource.id, row.resource.type, row.resource.fullname)} title='Aggiungi evento'>
                                <i className='fa fa-plus-circle mb-2' />
                            </a> : <></>}
                            {show ? <div key={index} className='event d-block p-1 pl-2 pr-2 mb-2 rounded small' style={{ background: color }}>
                                <div>
                                    {(value.type === 'P' || noPerson)
                                        ? <a className='view_btn' data-id={`${value.id}`} title='Visualizza evento'>
                                            <i className='fa fa-eye' /> Visualizza
                                        </a>
                                        : <></>
                                    }
                                </div>
                                {start.format('HH:mm')}-{end.format('HH:mm')}: {description}
                            </div> : <React.Fragment key={index}></React.Fragment>}
                        </React.Fragment>
                    }) : <a className='btn-add' onClick={() => this.onAddClick(Number(fixDate(day)), Number(fixDate(monthInterval[i])), yearInterval[i], row.resource.id, row.resource.type, row.resource.fullname)} title='Aggiungi evento'>
                        <i className='fa fa-plus-circle mb-2' />
                    </a>
                }
            })

            dayCount++;

            const dayDate: moment.Moment = moment(`${yearInterval[i]}-${fixDate(monthInterval[i])}-${fixDate(day)}`);
            const dayOfWeek = dayDate.weekday();

            if (dayOfWeek === 6 || (day === dayInterval[dayInterval.length - 1] && monthInterval[i] === monthInterval[monthInterval.length - 1])) {

                const weekOfYear = dayDate.week().toString();

                weekColumns.push(
                    {
                        id: `w_${weekOfYear}`,
                        name: weekOfYear,
                        headerColspan: dayCount
                    }
                )

                dayCount = 0;
            }

            return null
        })

        EventBus.dispatch("hideLoader");

        return {
            planningColumns: [
                [...weekColumns],
                [...dayColumns]
            ],
            planningData: planningDates
        }
    }

    renderModalContent() {
        const { planningDetails } = this.state;

        return planningDetails ? <>
            <hr />
            <div id="planning_details_joborder">
                <strong>Commessa:</strong>{' '}
                {/*<a className="text-primary" onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/contratti/commesse/' + planningDetails.joborder.id);
                }}>*/}{planningDetails.joborder.name} ({planningDetails.joborder.code}){/*</a>*/}
            </div>
            <br />
            <div id="planning_details_customer">
                <strong>Cliente:</strong>{' '}
                {/*<a className="text-primary" onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/contratti/clienti/' + planningDetails.customer.id);
                }}>*/}{planningDetails.customer.ragione_sociale}{/*</a>*/}
            </div>
            <br />
            <div id="planning_details_activities">
                <strong>Attività:</strong>{' '}
                {planningDetails.activities.length ? planningDetails.activities.map(
                    (activity: IPlanningActivity, index: number) => {
                        return <React.Fragment key={index}>
                            {/* <a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/contratti/attivita/' + activity.id);
                            }}>*/}{activity.descrizione}{/*</a> */}
                            {index < planningDetails.activities.length - 1 ? ' • ' : ''}
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_start">
                <strong>Data inizio:</strong>{' '}
                {moment(planningDetails.start).format('DD/MM/YYYY HH:mm')}
            </div>
            <br />
            <div id="planning_details_end">
                <strong>Data fine:</strong>{' '}
                {moment(planningDetails.end).format('DD/MM/YYYY HH:mm')}
            </div>
            <br />
            <div id="planning_details_people">
                <strong>Persone:</strong>{' '}
                {planningDetails.persons.length ? planningDetails.persons.map(
                    (person: IPlanningPeople, index: number) => {
                        return <React.Fragment key={index}>
                            <a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/organico/dettaglio-personale/' + person.id);
                            }}>{person.fullname}</a>
                            {index < planningDetails.persons.length - 1 ? ' • ' : ''}
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_devices">
                <strong>Strumenti:</strong>{' '}
                {planningDetails.devices.length ? planningDetails.devices.map(
                    (device: IPlanningDevice, index: number) => {
                        return <React.Fragment key={index}>
                            <a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/magazzino/dispositivi/dettaglio-dispositivo/' + device.id);
                            }}>{device.marca_modello} ({device.serial_number})</a>
                            {index < planningDetails.devices.length - 1 ? ' • ' : ''}
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_vehicles">
                <strong>Veicoli:</strong>{' '}
                {planningDetails.vehicles.length ? planningDetails.vehicles.map(
                    (vehicle: IPlanningVehicle, index: number) => {
                        return <React.Fragment key={index}>
                            {/*<a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/magazzino/veicoli/' + vehicle.id);
                        }}>*/}{vehicle.brand} {vehicle.model} ({vehicle.plate}){/*</a>*/}
                            {index < planningDetails.vehicles.length - 1 ? ' • ' : ''}
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_licenses">
                <strong>Licenze:</strong>{' '}
                {planningDetails.licenses.length ? planningDetails.licenses.map(
                    (license: IPLanningLicense, index: number) => {
                        return <React.Fragment key={index}>
                            <a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/magazzino/licenze/dettaglio-licenza/' + license.id);
                            }}>{license.prodotto.nome} ({license.identificativo})</a>
                            {index < planningDetails.licenses.length - 1 ? ' • ' : ''}
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_status">
                <strong>Stato:</strong>{' '}
                {planningDetails.stato.nome}
                <a className='btn btn-primary px-2 py-1 ms-3' onClick={async (e) => this.changeStatusHandler(planningDetails.id)}>Cambia</a>
            </div>
            <br />
            <div id="planning_details_priority">
                <strong>Priorità:</strong>{' '}
                {planningDetails.priority}
            </div>
            <br />
            <div id="planning_details_address">
                <strong>Indirizzo:</strong>{' '}
                {planningDetails.address}
            </div>
            <br />
            <div id="planning_details_note">
                <strong>Note:</strong>{' '}
                {planningDetails.note}
            </div>
            <hr />
            <a className='btn btn-warning mb-3 me-3' onClick={async (e) => this.editHandler(planningDetails.id)}>Modifica evento</a>
            <a className='btn btn-danger mb-3' onClick={async (e) => this.deleteHandler(planningDetails.id)}>Elimina evento</a>
        </> : <h2>Evento non trovato.</h2>
    }

    render() {
        const { showModal, showModalForm, modalFormTitle, modalFormType, formFields, formInitialValues } = this.state;

        return <React.Fragment>
            <Modal
                showModal={showModal}
                title={`Dettagli dell'evento`}
                type={'details'}
                closeCallback={this.closeModal.bind(this)}
                content={this.renderModalContent()}
            />
            <ModalForm
                id={`modal-form-${modalFormType}`}
                showModal={showModalForm}
                title={modalFormTitle}
                modalType={modalFormType}
                formFields={formFields}
                initialValues={formInitialValues}
                apiSubmit={this.apiSubmit}
                closeCallback={this.closeModalForm.bind(this)}
                successAction={this.handleSuccess.bind(this)}
            />
            <div id="planning" className='custom-container'>
                <div className="card">
                    <div className="card-body">
                        <PlanningMonth
                            year={this.state.year}
                            month={this.state.month}
                            setYearMonth={async (
                                year: number,
                                month: number
                            ) => {
                                this.setState({ year, month });
                            }}
                            getMonth={this.getMonth.bind(this)}
                            getInterval={this.getInterval.bind(this)}
                            viewHandler={this.viewHandler.bind(this)}
                            addHandler={this.addHandler.bind(this)}
                            type='planning'
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}